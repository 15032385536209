
import store from '@/store'

export const makePointFilterFrom = (searchString: string): Function => {
  const lowerCaseSearchString = searchString.toLowerCase()
  
  const isInLabel = (point: any): boolean => point.label?.toLowerCase().includes(lowerCaseSearchString)
  const isInDescription = (point: any): boolean => point.details?.content?.toLowerCase().includes(lowerCaseSearchString)
  const isInFolderName = (folder: any): boolean => folder?.label?.toLowerCase().includes(lowerCaseSearchString)
  
  const isMatch = (point: any): boolean => {
    if (point.noSearch) {
      return false
    }
    
    if (isInLabel(point) || isInDescription(point)) {
      return true
    }
    
    const folders = store.getFoldersContainingPoint(point)
    for (const folder of folders) {
      if (isInFolderName(folder)) {
        return true
      }
    }
    
    return false
  }
  
  return isMatch
}
