<template>
  <div id="search-results" v-if="searchString">
    <div class="close-button">
      <ion-button @click="close" color="light" fill="clear" size="small">
        <ion-icon src="assets/icon/close.svg"></ion-icon>
      </ion-button>
    </div>
    <p class="label">Search results for "{{searchString}}" ({{searchResults.length}})</p>
    <div class="content">
      <ion-list id="folder-list">
        <ion-item
            style="padding-left: 1ex"
            v-for="(p, j) in searchResults" :key="j"
            router-direction="root"
            :router-link="assembleRouterLinkFrom({ accountCodeName, point: p, folder: getFolder(p.folder), submap: getSubmap(p.parentSubmap) })"
            lines="none" detail="false" class="hydrated">
          <ion-label style="margin: 0;">
            <div :style="{ backgroundImage: accountCodeName && p.photos && p.photos[0] ? `url('https://pointsmap.com/${accountCodeName}/data/photos/${encodeURIComponent(p.photos[0])}')` : 'none' }"
                 class="point-label-bg" v-bind:class="{'logo-image': p.primaryPhotoIsLogo}">
              <div class="point-label">{{ p.label }}</div>
            </div>
          </ion-label>
        </ion-item>
      </ion-list>
      <div v-if="searchResults.length === 0" class="no-results-message">
        No matches
      </div>
    </div>
  </div>
</template>

<script>
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
} from "@ionic/vue"
import { assembleRouterLinkFrom } from '@/helpers/links';
import { makePointFilterFrom } from "@/helpers/search"
import router from "@/router";
import store from "@/store";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

const accountCodeName = ref(store.state.accountCodeName)
const searchString = ref('')

export default {
  name: "PointDetails",
  components: {
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
  },
  computed: {
    searchResults() {
      const isMatchingPoint = makePointFilterFrom(searchString.value)
      const points = Object.values(store.state.pointsByGuid)
      return points.filter(isMatchingPoint)
    }
  },
  methods: {
    close() {
      searchString.value = ''
      router.back()
    },
  },
  setup() {
    const route = useRoute()
    searchString.value = route.params.searchString

    setTimeout(() => {
      accountCodeName.value = store.state.accountCodeName
    })

    watch(
        () => route.params.searchString,
        async () => {
          searchString.value = route.params.searchString
        }
    )

    return {
      accountCodeName,
      assembleRouterLinkFrom,
      getFolder: store.getFolder,
      getSubmap: store.getSubmap,
      searchString,
    }
  },
}
</script>

<style scoped>
.no-results-message {
  font-style: italic;
  text-align: center;
}

.point-label {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  white-space: initial;
}

.point-label-bg {
  position: relative;
  min-height: 90px;
  margin: 3px 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.point-label-bg.logo-image {
  background-color: #fff;
  background-size: contain;
}

#search-results {
  background-color: var(--ion-background-color, #fff);
  color: var(--ion-text-color, #111111);
  position: absolute;
  bottom: 0;
  height: 50vh;
  width: 100%;
  padding: 1ex;
  border-top-left-radius: 1ex;
  border-top-right-radius: 1ex;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
  overflow-x: auto;
}

#search-results .close-button {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
}
#search-results .close-button ion-icon {
  color: #111;
}

#search-results .label {
  font-weight: bold;
  margin: 0.25ex;
  padding: 0.5ex 3ex;
  text-align: center;
}
</style>
